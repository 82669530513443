import { Button, Card, Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RaiseCommunicationAction } from "redux/actions/RaiseCommunication/raiseCommunication.action";
import { getLocalStorageItems, getValidDataFromResponse, SendNotification } from "utils/utilities";
import * as yup from "yup";
import { DATE_FORMAT_DD_MM_YYYY } from "../../constants/DateConstant";

const AddNewCommunication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const formSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    query_detail: yup.string().required("Query details is required"),
    room: yup.string().required("Room number is required"),
  });

  const yupSync = {
    async validator({ field }, value) {
      await formSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const handleFormSubmit = (value) => {
    setLoading(true);

    let { facility_id } = getLocalStorageItems({ getAll: true, keys: ["facility_id"] });

    let body = {
      ...value,
      date: moment(value.date).format("YYYY-MM-DD"),
      facility_id,
      status_id: 1,
      generated_by: localStorage.getItem("id"),
    };
    dispatch(RaiseCommunicationAction(body))
      .then((response) => {
        const { success, message } = getValidDataFromResponse(response);

        if (success) {
          SendNotification({ type: "success", message });
          navigate(-1);
          return;
        }
        SendNotification({ type: "error", message });
      })
      .finally(() => setLoading(false));
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const isGuest = () => {
    return pathname.split("/").includes("g") ? true : false;
  };

  const datePickerProps = {
    className: "w-100",
    disabledDate: disabledDate,
    defaultValue: moment(Date.now()),
    disabled: isGuest() ? true : false,
    format: DATE_FORMAT_DD_MM_YYYY,
    allowClear: false,
  };

  return (
    <div className="container">
      <div>
        <Row align="middle" className="justify-content-sm-start justify-content-center">
          <div className="text-center p-3">
            <h1>Add Communication</h1>
          </div>
        </Row>
        <Row>
          <Col span={24}>
            <Card>
              <Form layout="vertical" onFinish={(value) => handleFormSubmit(value)}>
                <Row gutter={8}>
                  <Col span={24} xs={24} sm={12}>
                    <Form.Item label="Date" name="date">
                      <DatePicker {...datePickerProps} />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={12}>
                    <Form.Item label="Name" name="name" rules={[yupSync]}>
                      <Input className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Details/Issue/Spill " name="query_detail" rules={[yupSync]}>
                      <Input.TextArea className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={12}>
                    <Form.Item label="Room/Area" name="room" rules={[yupSync]}>
                      <Input className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={12}>
                    <Form.Item label="Spill Type or N/A " name="spill_type">
                      <Input className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <Button loading={loading} type="primary" htmlType="submit" className="w-100">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddNewCommunication;
