import { Fragment } from "react";
import majesticLogo from "../../assets/images/asepsis-logo.png";

const Logo = () => {
  return (
    <Fragment>
      <img src={majesticLogo} alt="Asepsis logo" />
    </Fragment>
  );
};

export default Logo;
