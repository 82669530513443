import { Card, Col, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import majesticLogo from "../../assets/images/asepsis-logo.svg";
import LoginForm from "./form";

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [facility_id, setFacilityId] = useState(() => searchParams.get("fid"));

  useEffect(() => {
    if (facility_id) {
      localStorage.setItem("facility_id", facility_id);
    }
  }, [facility_id]);

  return (
    <Fragment>
      <div className="login_container">
        <div className="container d-flex flex-column justify-content-center h-100">
          <Row justify="center">
            <Col xs={20} sm={20} md={20} lg={10} xl={7}>
              <Card>
                <div className="my-4">
                  <div className="text-center">
                    <img className="img-fluid" src={majesticLogo} alt="" />
                    {/* <p>Don't have an account yet? <a href="/auth/register-1">Sign Up</a></p> */}
                  </div>
                  <Row className="mt-5" justify="center">
                    <Col xs={24} sm={24} md={20} lg={20}>
                      <LoginForm fid={facility_id} />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
