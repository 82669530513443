const errorInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("🚀 ~ axiosInstance.interceptors.response.use ~ error:", error);
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
      }
    }
  );
};

export default errorInterceptor;
