import { Card, Col, Row } from "antd";
import { CommunicationIcon, DashboardIcon } from "assets/icons";
import { StickySubHeader } from "components";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const Dashboard = ({ isGlobalNavigation, closeNav }) => {
  return (
    <Fragment>
      {!isGlobalNavigation && (
        <StickySubHeader>
          <div className="dashboard_header">
            <div className="container">
              <Row className=" justify-content-center justify-content-sm-start">
                <h2>{localStorage.getItem("facility_name")}</h2>
              </Row>
            </div>
          </div>
        </StickySubHeader>
      )}

      <div className="container">
        <Row gutter={[16]} className="mt-5">
          <Col xs={24} sm={12}>
            <Link onClick={() => closeNav()} to="/all-communications">
              <Card>
                <div className="d-flex flex-column align-items-center justify-content-center dashboard_card">
                  <div>
                    <DashboardIcon width="130px" />
                  </div>
                  <div>
                    <h3 className="mt-4">Explore Dashboard</h3>
                  </div>
                </div>
              </Card>
            </Link>
          </Col>

          <Col xs={24} sm={12}>
            <Link onClick={() => closeNav()} to="/raise-communications">
              <Card>
                <div className="d-flex flex-column align-items-center justify-content-center dashboard_card">
                  <div>
                    <CommunicationIcon width="130px" />
                  </div>
                  <div>
                    <h3 className="mt-4">Raise Communication</h3>
                  </div>
                </div>
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

Dashboard.defaultProps = {
  isGlobalNavigation: false,
  closeNav: () => {},
};

export default Dashboard;
